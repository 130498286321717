import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../hooks/useHomepage'
import Plan20MealImage from '../../images/mealpass/20-meal-plan/20meal_group_desktop.png'
import UtmContent from '../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../services'
import { Plan as PlanType } from '../../types'

type Props = {
  plan: PlanType
}

const Plan: React.FC<Props> = ({ plan }) => {
  const { showModal, launchConfirmationRequired, onMealPassPage } = useHomepage()
  const { t } = useTranslation()

  const onClickHandler = (e: React.MouseEvent, plan: PlanType) => {
    if (launchConfirmationRequired) {
      e.preventDefault()

      return showModal({ name: 'get-access-form' })
    }

    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Choose this plan',
      value: plan.id,
    })
  }

  const buildPlanHref = (plan: PlanType): string => {
    const utmContent = new UtmContent({ plan, onMealPassPage }).toString()

    return UrlService.build(utmContent, plan)
  }

  return (
    <div className="plans-mealpass__box">
      <div className="plans-mealpass__box-header">
        <div className={`plans-mealpass__box-title`}>{plan.planBoxTitle}</div>
        <div className="plans-mealpass__box-credit">{plan.display_price}</div>
      </div>
      <img className="plans-mealpass__image" src={Plan20MealImage} />
      <div className="plans-mealpass__box-info">
        <div className="plans-mealpass__price">
          <a
            href={buildPlanHref(plan)}
            onClick={(e) => onClickHandler(e, plan)}
            className="plans-mealpass__action red-cta"
          >
            {t('Get MealPass')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Plan

import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

const Heading = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="plans-mealpass__title">{t('Insane prices, Tons of options')}</div>
      <div className="plans-mealpass__subtitle">{t('Meals are served every weekday, excluding holidays.')}</div>
    </>
  )
}

export default Heading

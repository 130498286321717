import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import EnjoyImage from '../../images/mealpass/how-its-work/enjoy_icon.png'
import ReserveImage from '../../images/mealpass/how-its-work/reserve_icon.png'
import SaveImage from '../../images/mealpass/how-its-work/save_icon.png'
import './how-it-works.less'

const HowItWorksContainer = () => {
  const { t } = useTranslation()

  return (
    <div className="mealpass-how-it-works backgroud-white">
      <div className="mealpass-how-it-works__title">{t('How It Works')}</div>
      <div className="mealpass-how-it-works__advantages">
        {t('The MealPass plan is smart, affordable and convenient.')}
      </div>
      <div className="mealpass-how-it-works__details">
        <div className="mealpass-how-it-works__column">
          <div className="mealpass-how-it-works__icon">
            <img src={ReserveImage} width="55" height="105" />
          </div>
          <div className="mealpass-how-it-works__subtitle">{t('Reserve')}</div>
          <div className="mealpass-how-it-works__description">
            {t('Choose from hundreds of')}
            <br />
            {t('meals each day.')}
          </div>
        </div>
        <div className="mealpass-how-it-works__column">
          <div className="mealpass-how-it-works__icon">
            <img src={SaveImage} width="110" height="107" />
          </div>
          <div className="mealpass-how-it-works__subtitle">Save</div>
          <div className="mealpass-how-it-works__description">
            {t('Never pay full price')}
            <br />
            {t('for lunch again.')}
          </div>
        </div>
        <div className="mealpass-how-it-works__column">
          <div className="mealpass-how-it-works__icon">
            <img src={EnjoyImage} width="112" height="106" />
          </div>
          <div className="mealpass-how-it-works__subtitle">Enjoy</div>
          <div className="mealpass-how-it-works__description">
            {t('Skip the line.')}
            <br />
            {t('Your meal will be ready!')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksContainer

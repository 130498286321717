import { filter, find, isEmpty, orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import useHomepage from '../../hooks/useHomepage'
import { Plan as PlanType } from '../../types'
import Heading from './Heading'
import Plan from './Plan'
import Taxes from './Taxes'
import './plans.less'

const PlansContainer = () => {
  const { plans, productOffering, activeCityName, cities } = useHomepage()
  const [currentPlans, setCurrentPlans] = useState<PlanType[]>([])

  useEffect(() => {
    if (!isEmpty(plans) && !isEmpty(cities)) {
      const activeCity = find(cities, (city) => city.name.toLowerCase() === activeCityName.toLowerCase())
      const currentPlans = orderBy(
        filter(
          plans,
          (plan: PlanType) => plan.city.id === activeCity?.id && plan.id !== '5b37e41f-8fce-45d9-b61a-a9d1ca2c66fd',
        ),
        'order',
      ).slice(0, 2)

      setCurrentPlans(currentPlans)
    }
  }, [plans, cities, activeCityName])
  const currentPlan = {
    ...currentPlans[0],
    id: '5f6d6d33-e2cd-4f7f-9a23-dc0e0054f0e1',
    meals: 20,
    display_price: '$5.99/meal',
    planBoxTitle: '20 meals',
  }

  return (
    <div className={`plans-mealpass plans-mealpass--${productOffering}`}>
      <Heading />
      <Plan plan={currentPlan} />
      <Taxes />
    </div>
  )
}

export default PlansContainer

import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

const Taxes = () => {
  const { t } = useTranslation()

  return <div className="plans-mealpass__description">{t('Taxes and fees are additional.')}</div>
}

export default Taxes

import { useI18next } from 'gatsby-plugin-react-i18next'
import React, { useEffect } from 'react'

import FooterForm from '../footer-form/Container'
import Hero from '../mealpass-hero/Container'
import HowItWorks from '../mealpass-how-it-works/Container'
import Partners from '../mealpass-our-partners/Container'
import Plans from '../mealpass-plans/Container'
import SEO from '../seo'

type Props = {
  path: string
}

const LandingContainer: React.FC<Props> = () => {
  const language = useI18next().language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [language])

  return (
    <>
      <SEO title="Home" />
      <Hero />
      <Partners />
      <Plans />
      <HowItWorks />
      <FooterForm />
    </>
  )
}

export default LandingContainer

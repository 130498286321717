import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useLayoutEffect } from 'react'

import useHomepage from '../../hooks/useHomepage'
import mealPass_logo from '../../images/mealpass/mealpass-logo.png'
import Form from '../form/Form'

const HeroForm = () => {
  const { cities } = useHomepage()
  const position = 'mealpass'

  const { t } = useTranslation()
  const language = useI18next().language

  useLayoutEffect(() => {
    window.scrollTo(2, 2)
  }, [])

  return (
    <div>
      <div className={`hero__form hero__form--${language}`}>
        <div>
          <span className="hero-title-mealpass">{t('Get our original plan:')}</span>
          <br />
          <img src={mealPass_logo} alt="MealPass-Logo" className="mealpass__logo" />
        </div>
        <div className="hero__title">
          {t('hero-title-line-one')}
          <br />
          {t('hero-title-line-two')}
        </div>
        <div className="hero__subtitle">{t('hero-subtitle')}</div>
        <Form customClass="form--hero" position={position} cities={cities} />
      </div>
    </div>
  )
}

export default HeroForm
